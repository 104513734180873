// import { FaXmark } from "react-icons/fa6";
import { FaHeart } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import { MdShare } from "react-icons/md";

import { Link } from "react-router-dom";

import ReactSlider from "react-slider";

import cn from "classnames";

import "./Controls.css";
import { useRef } from "react";

function MainControls({ next, closeCard, openCard, urlName }) {
  const thumb = useRef(null);
  return (
    <div className="controls">
      <div className="rate-wrapper">
        <ReactSlider
          className="rate"
          markClassName="mark"
          renderMark={(props) => {
            return (
              <span {...props} key={props.key}>
                {props.key + 1}
              </span>
            );
          }}
          thumbClassName="thumb"
          trackClassName="track"
          defaultValue={2}
          min={0}
          max={4}
          marks
          onAfterChange={(e) => {
            thumb.current.classList.add("loading");
            setTimeout(() => {
              thumb.current.classList.remove("loading");
            }, 100 + Math.random() * 400);
          }}
          renderThumb={(props, state) => {
            return (
              <div
                {...props}
                style={{
                  ...props.style,
                  ...(() => {
                    if (state.valueNow === 3) {
                      return {
                        background: "linear-gradient(90deg, #a7b29c, #F3A701)",
                      };
                    }
                    if (state.valueNow === 4) {
                      return {
                        background: "linear-gradient(90deg, #F3A701, #E8392B)",
                      };
                    }
                  })(),
                }}
                className={cn(props.className, {
                  hot: state.valueNow === 4,
                  not: state.valueNow === 0,
                })}
                ref={(ref) => {
                  thumb.current = ref;
                  props.ref(ref);
                }}
                key={props.key}
              >
                {state.valueNow + 1}
              </div>
            );
          }}
        />
      </div>
      <button className="like" onClick={openCard}>
        <FaHeart />
      </button>
      <button
        className="share"
        onClick={async () => {
          try {
            await navigator.share({
              url: urlName,
              text: "Check out this babe!",
            });

            if (typeof window.idsync !== "undefined") {
              window.idsync.send_event({
                name: urlName,
                type: "Acquisition",
                value: "0.100",
              });
            }

            // eslint-disable-next-line no-undef
            if (typeof fbq !== "undefined") {
              // eslint-disable-next-line no-undef
              fbq("track", "Lead");
            }
          } catch (e) {
            console.log(e);
          }
        }}
      >
        <MdShare /> Share with a friend
      </button>
      <Link to={next} className="dismiss" onClick={closeCard}>
        <FaArrowRight />
      </Link>
    </div>
  );
}

export default MainControls;
