import { FaMessage } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";

import { Link } from "react-router-dom";

import "./Controls.css";

function CardControls({ next, closeCard, urlName, link }) {
  return (
    <div className="buttons">
      <Link to={next} className="next" onClick={closeCard}>
        <FaArrowRight />
      </Link>
      <button
        className="message"
        onClick={(e) => {
          e.preventDefault();

          // if (typeof window.idsync !== "undefined") {
          //   window.idsync.send_event({
          //     name: urlName,
          //     type: "Acquisition",
          //     value: "0.100",
          //   });
          // }

          window.open(link, "_blank");
        }}
      >
        <FaMessage />
      </button>
    </div>
  );
}

export default CardControls;
