import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "./App.css";

import data from "./data";
import Card from "./Card";
import Policy from "./Policy";

const cards = data.map((d) => ({
  ...d,
  urlName: d.name
    .toLocaleLowerCase()
    .replaceAll(/[^\w\d-\s]/g, "")
    .trim()
    .replaceAll(" ", "-"),
}));

function Page() {
  let location = useLocation();

  return (
    <TransitionGroup style={{ display: "contents" }}>
      <CSSTransition key={location.pathname} classNames="move" timeout={550}>
        <Switch location={location}>
          {cards.map((c, i, all) => (
            <Route
              key={c.name}
              path={`/${c.urlName}`}
              children={
                <Card
                  {...c}
                  next={all?.[i + 1]?.urlName || all?.[0]?.urlName}
                />
              }
            />
          ))}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

function App() {
  return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to={`/${cards[0].urlName}`} />
          </Route>
          <Route exact path="/privacy-policy">
            <Policy />
          </Route>
          <Route path="*">
            <Page />
          </Route>
        </Switch>
      </Router>
  );
}

export default App;
