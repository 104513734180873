import dr1 from "./assets/Diana Rider/1.jpeg";
import dr2 from "./assets/Diana Rider/2.jpeg";
import dr3 from "./assets/Diana Rider/3.jpeg";
import dr4 from "./assets/Diana Rider/4.jpeg";

import sp1 from "./assets/Sofia Parker/1.jpeg";
import sp2 from "./assets/Sofia Parker/2.jpeg";
import sp3 from "./assets/Sofia Parker/3.jpeg";

import ad1 from "./assets/Anastasia Doll/1.jpeg";
import ad2 from "./assets/Anastasia Doll/2.jpeg";
import ad3 from "./assets/Anastasia Doll/3.jpeg";

import mm1 from "./assets/Mona Maria/1.jpeg";
import mm2 from "./assets/Mona Maria/2.jpeg";
import mm3 from "./assets/Mona Maria/3.jpeg";

import hz1 from "./assets/Hanna Zimmer/1.jpeg";
import hz2 from "./assets/Hanna Zimmer/2.jpeg";
import hz3 from "./assets/Hanna Zimmer/3.jpeg";
import hz4 from "./assets/Hanna Zimmer/4.jpeg";

import cs1 from "./assets/Candy Smile/1.jpeg";
import cs2 from "./assets/Candy Smile/2.jpeg";
import cs3 from "./assets/Candy Smile/3.jpeg";

import ya1 from "./assets/Your Angel/1.jpeg";
import ya2 from "./assets/Your Angel/2.jpeg";
import ya3 from "./assets/Your Angel/3.jpeg";

import o1 from "./assets/Octokuro/1.jpeg";
import o2 from "./assets/Octokuro/2.jpeg";
import o3 from "./assets/Octokuro/3.jpeg";

import sf1 from "./assets/Sweetie Fox/1.jpeg";
import sf2 from "./assets/Sweetie Fox/2.jpeg";
import sf3 from "./assets/Sweetie Fox/3.jpeg";

const data = [
  {
    name: "Diana Rider",
    text: "Don’t be shy! Come chat with me 😘 I am here to make your day happier and something else harder 🤤😈",
    link: "https://onlyfans.com/action/trial/i2b12lv3fu8jnsg9ht1heeaoqbdwxn0z",
    images: [dr1, dr2, dr3, dr4],
  },
  {
    name: "Sofia Parker",
    text: "I'm Sofia, your sporty muse and fitness trainer who knows how to mix health with pleasure 💪 Love to treat myself to some sweet sins 🍰",
    link: "https://onlyfans.com/sofiaaaparker/c253",
    images: [sp1, sp2, sp3],
  },
  {
    name: "Anastasia Doll",
    text: "Finally a place I can be myself. ❤️😛 Welcome to the world of big boobs 😏 🔥👀💋",
    link: "https://onlyfans.com/anastasiadollofficial/c53",
    images: [ad1, ad2, ad3],
  },
  {
    name: "🎨 Mona Maria",
    text: "I am an artist, I paint a lot and often try to realize my imagination on a blank canvas 👩‍🎨 Now my creativity has turned into too explicit and I think it's a sign to open up to you 🎨",
    link: "https://onlyfans.com/monamariaa/c87",
    images: [mm1, mm2, mm3],
  },
  {
    name: "Hanna Zimmer",
    text: "Hi honey! I am Hanna, a naughty Geography teacher, who likes driving to school without driving license and panties 🙈",
    link: "https://onlyfans.com/action/trial/ur6anh4brqtxj20ezk7dtz51woazlwer",
    images: [hz1, hz2, hz3, hz4],
  },
  {
    name: "Candy Smile",
    text: " like to share my life here, I think you've already noticed my posts about my hobbies and activities, but I think you want to know what this candy smile is capable of in private messages 👿",
    link: "https://onlyfans.com/smile2003/c325",
    images: [cs1, cs2, cs3],
  },
  {
    name: "Your Angel",
    text: "I am very kind and naive, these qualities of mine cause a lot of problems in my life and here I want to find a kind person like me 🥺 I still hope that there are people who like to do good and can be sincere 🥰",
    link: "https://onlyfans.com/yourangel07/c127",
    images: [ya1, ya2, ya3],
  },
  {
    name: "Octokuro ⭐️",
    text: "Hey there! I'm Octokuro — naughty girl who loves xxx cosplay 😏",
    link: "https://onlyfans.com/action/trial/ws7wsgiptdp0iqcjaefke4u4wyweysdt",
    images: [o1, o2, o3],
  },
  {
    name: "Sweetie Fox ⭐️",
    text: "Your favorite cute cosplayer 🥰 Can't wait to show you my hot content to make you cum 💦😏",
    link: "https://onlyfans.com/sweetiefox_of/c165",
    images: [sf1, sf2, sf3],
  },
  {
    name: "🌼 Venessa Rey",
    text: "Hey there, cutie! 💋 I'm Vanessa, a shapely and stunning beauty 👩🏻‍🦱 I'm not just your average gamer girl who loves anime 🎮🌸 I'm looking to explore new boundaries of pleasure, especially in the exhilarating world of BDSM 😈",
    link: "https://onlyfans.com/venessarey/c63",
    images: (
      await Promise.all([
        import("./assets/AG184/1.jpeg"),
        import("./assets/AG184/2.jpeg"),
        import("./assets/AG184/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "Hi, i'm Molly 🍓",
    text: "I'm a girl with an infectious smile and an incredibly soft heart. My passion for life knows no bounds and my eyes shine brightly with a love for beautiful things. I'm here to inspire you to do great things and bring some light and joy to your reality 🫀",
    link: "https://onlyfans.com/mollylo/c114",
    images: (
      await Promise.all([
        import("./assets/AG185/1.jpeg"),
        import("./assets/AG185/2.jpeg"),
        import("./assets/AG185/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "🥥 Eva Miller",
    text: "Welcome to my page! I'm going to make you wish I lived right next door 😜",
    link: "https://onlyfans.com/eva_millerr/c147",
    images: (
      await Promise.all([
        import("./assets/AG193/1.jpeg"),
        import("./assets/AG193/2.jpeg"),
        import("./assets/AG193/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "Zoe's Paws",
    text: "As a lover of cats, creativity, and all things cute, I'm on a mission to spread joy and positivity wherever I go. With a dash of confidence and a sprinkle of mischief, I'm here to make your day a little brighter and your heart a little lighter 🤍",
    link: "https://onlyfans.com/murrawrr/c107",
    images: (
      await Promise.all([
        import("./assets/AG194/1.jpeg"),
        import("./assets/AG194/2.jpeg"),
        import("./assets/AG194/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "Anita Cutie",
    text: "I'm feeling very humble, to remedy that I decided to create this account. I hope I will be able to become more liberated",
    link: "https://onlyfans.com/anitacutie0/c39",
    images: (
      await Promise.all([
        import("./assets/AG200/1.jpeg"),
        import("./assets/AG200/2.jpeg"),
        import("./assets/AG200/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "Olash 🍑",
    text: "Hello my sweet soul! 💞 My heart is filled with love and passion for sharing the most exquisite parts of my being with the world 😏",
    link: "https://onlyfans.com/action/trial/kvz6tqdc6yt2jvenlzphdtan6yaa54hu",
    images: (
      await Promise.all([
        import("./assets/CO9/1.jpeg"),
        import("./assets/CO9/2.jpeg"),
        import("./assets/CO9/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "Elizabeth Angel",
    text: "A little Angel with big shapes 👀 Natural big Boobs ❤️‍🔥 CUMPUPPY WANTS UR DICK PICS 💦",
    link: "https://onlyfans.com/elizabeth_angel/c195",
    images: (
      await Promise.all([
        import("./assets/FU4/1.jpeg"),
        import("./assets/FU4/2.jpeg"),
        import("./assets/FU4/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "✨ Alice in Wonderland",
    text: "I am a crazy and bright girl, about whom you can say a lot of bad things, but nothing will change my passionate desire to know the world and myself in it. I am often alone, I think, I dream and sometimes I try to arrange my life. But I spend most of my time looking for new experiences 🌈",
    link: "https://onlyfans.com/aliceinwonderland_xo/c247",
    images: (
      await Promise.all([
        import("./assets/MC14/1.jpeg"),
        import("./assets/MC14/2.jpeg"),
        import("./assets/MC14/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "Angela 💖",
    text: "🍑 Sweetest peach in the place, will you try it?",
    link: "https://onlyfans.com/action/trial/y4r65esdawlglcqlm4hpzckhqhhis67l",
    images: (
      await Promise.all([
        import("./assets/ON302/1.jpeg"),
        import("./assets/ON302/2.jpeg"),
        import("./assets/ON302/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "Virgin girl",
    text: "𝐌𝐲 𝐧𝐚𝐦𝐞 𝐢𝐬 𝐒𝐨𝐧𝐲𝐚, 𝐈`𝐦 𝟏𝟗 𝐲.𝐨. 🦄 𝐈 𝐚𝐦 𝐚 𝐯𝐢𝐫𝐠𝐢𝐧 𝐚𝐧𝐝 𝐢𝐧𝐞𝐱𝐩𝐞𝐫𝐢𝐞𝐧𝐜𝐞𝐝, 𝐛𝐮𝐭 𝐈 𝐝𝐫𝐞𝐚𝐦 𝐭𝐨 𝐟𝐢𝐧𝐝 𝐚 𝐦𝐚𝐧 𝐰𝐡𝐨 𝐰𝐢𝐥𝐥 𝐡𝐞𝐥𝐩 𝐦𝐞 𝐭𝐨 𝐛𝐞𝐜𝐨𝐦𝐞 𝐦𝐨𝐫𝐞 𝐞𝐱𝐩𝐞𝐫𝐢𝐞𝐧𝐜𝐞𝐝 𝐢 𝐰𝐚𝐧𝐭 𝐭𝐨 𝐭𝐫𝐲 𝐢𝐭! 😇",
    link: "https://onlyfans.com/virgin_baby_girl/c680",
    images: (
      await Promise.all([
        import("./assets/PE1/1.jpeg"),
        import("./assets/PE1/2.jpeg"),
        import("./assets/PE1/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "Skyress 💋",
    text: "❤️‍🔥 𝟏𝟖-𝐲𝐞𝐚𝐫-𝐨𝐥𝐝 𝐠𝐢𝐫𝐥 𝐰𝐢𝐭𝐡𝐨𝐮𝐭 𝐭𝐚𝐛𝐨𝐨𝐬 ❤️‍🔥",
    link: "https://onlyfans.com/skyress_vip/c486",
    images: (
      await Promise.all([
        import("./assets/PE3/1.jpeg"),
        import("./assets/PE3/2.jpeg"),
        import("./assets/PE3/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "🔥 Alina 🤸🏼‍♀️",
    text: "Hey stranger 🍀❤️ wanna know the coolest girl on OF? 🔥😈 I'm open book for you 🙈",
    link: "https://onlyfans.com/action/trial/htki6zhzcj5biicp1gvglhmemiwcxkw1",
    images: (
      await Promise.all([
        import("./assets/SZ1/1.jpeg"),
        import("./assets/SZ1/2.jpeg"),
        import("./assets/SZ1/3.jpeg"),
      ])
    ).map((m) => m.default),
  },
  {
    name: "Monic",
    text: "𝐈𝐬𝐥𝐚𝐧𝐝 𝐆𝐢𝐫𝐥 🏝️ 𝐈'𝐦 𝐚 𝐦𝐨𝐝𝐞𝐥, 𝐥𝐨𝐯𝐞 𝐚𝐫𝐭 𝐚𝐧𝐝 𝐟𝐫𝐞𝐞𝐝𝐨𝐦 𝐢𝐧 𝐚𝐥𝐥 𝐟𝐨𝐫𝐦𝐬 ☘️❤️ 𝐇𝐞𝐫𝐞 𝐈 𝐚𝐦 𝐫𝐞𝐚𝐝𝐲 𝐭𝐨 𝐬𝐡𝐚𝐫𝐞 𝐦𝐲 𝐞𝐱𝐜𝐥𝐮𝐬𝐢𝐯𝐞 𝐩𝐡𝐨𝐭𝐨𝐬 𝐚𝐧𝐝 𝐯𝐢𝐝𝐞𝐨𝐬 𝐰𝐢𝐭𝐡 𝐲𝐨𝐮. 🔞",
    link: "https://onlyfans.com/definy_free/c85",
    images: (
      await Promise.all([
        import("./assets/SZ7/1.jpeg"),
        import("./assets/SZ7/2.jpeg"),
        import("./assets/SZ7/3.jpeg"),
      ])
    ).map((m) => m.default),
  },

  {
    name: "Elizabeth Definy 😈",
    text: "Nice to see you here 🫦 You can call me Liza 😉 THE MOST POPULAR UKRAINIAN TATTOED MODEL 😈🫦🔥",
    link: "https://onlyfans.com/definy_free/c85",
    images: (
      await Promise.all([
        import("./assets/SZ8/1.jpeg"),
        import("./assets/SZ8/2.jpeg"),
        import("./assets/SZ8/3.jpeg"),
      ])
    ).map((m) => m.default),
  },

  // {
  //   name: "",
  //   text: "",
  //   link: "",
  //   images: (await Promise.all([
  //     import("./assets/"),
  //     import("./assets/"),
  //     import("./assets/"),
  //   ])).map((m) => m.default),
  // },
];

export default data;
